import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import BaseTextField from './BaseTextField';

export default function NumberTextField({ decimal, control, name, ...props }) {
  const valueWatch = useWatch({ control, name });
  const [useComma, setUseComma] = useState(false);

  return (
    <BaseTextField
      control={control}
      name={name}
      onBlur={(e, onChange) => {
        let val = e.target.value;
        setUseComma(val.includes?.(','));
        val = parseFloat(val?.replace?.(',', '.'));
        if (val) {
          val = val.toFixed(decimal);
        }
        onChange(val);
      }}
      onFocus={() => setUseComma(false)}
      inputMode={'numeric'}
      valueFormatter={() =>
        useComma ? valueWatch?.toString().replace('.', ',') : valueWatch
      }
      {...props}
    />
  );
}

NumberTextField.propTypes = {
  decimal: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
